import Vue from 'vue';
import App from './App.vue';
import router from './router';
import './primeVue';
import './thirdPartyImports';
import './assets/layout/layout.scss';
import {dom} from '@fortawesome/fontawesome-svg-core'
import axios from "axios";

dom.watch()

Vue.config.productionTip = false;

axios.interceptors.response.use(null,
    error => {
        if (401 === error.response.status) {
            router.push('/logout')
        }
        return Promise.reject(error);
    }
)

new Vue({
    router,
    render: h => h(App)
}).$mount('#app');
