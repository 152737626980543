<template>
  <div id="c1" style="overflow-y: hidden">
    <Menubar :model="items">
      <template #start>
        <a href="/">
          <img
            alt="logo"
            src="./assets/layout/images/elmers_logo.png"
            height="40"
            class="mr-2"
          />
        </a>
      </template>
    </Menubar>
    <div class="layout-main" style="overflow-y: auto; overflow-x: hidden">
      <router-view style="margin-bottom: 5em; min-height: 80vh" />
    </div>
  </div>
</template>

<script>
export default {
  name: "App.vue",
  data() {
    return {
      visible: false,
      dismissable: true,
      items: [
        {
          label: "Maps",
          items: [
            {
              label: "Asset Map",
              icon: "pi pi-map",
              to: "/",
            },
                  {
              label: "Report Maps",
              icon: "pi pi-map",
              to: "/ReportMap",
            },
          ],
        },
      ],
    };
  },
  mounted() {},
};
</script>

<style scoped>
div .layout-main {
  background-color: white;
  padding: 1em;
}
</style>
