import Vue from 'vue';
import Router from 'vue-router';
import authService from "./service/authService";

Vue.use(Router);

const router = new Router({
    mode: "history",
    routes: [
        {
            name: 'AssetMap',
            path: '/',
            meta: {requiresAuth: true},
            component: () => import('./components/AssetMap.vue')
        },
        {
            name: 'loginPage',
            path: '/login',
            meta: {requiresAuth: false},
            component: () => import('./components/LoginPage.vue')
        },
        {
            name: 'logout',
            path: '/logout',
            meta: {requiresAuth: true},
        },
        {
            name: 'ReportMap',
            path: '/reportMap',
            meta: {requiresAuth: true},
            component: () => import('./components/ReportMap.vue')
        }
    ],
    scrollBehavior() {
        return {x: 0, y: 0};
    }
});

router.beforeEach((to, from, next) => {
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth);

    try {
        if (requiresAuth && to.path === '/logout') {
            authService.logout()
            next('/login')
        } else {
            if (requiresAuth && !authService.loggedIn()) {
                next('login')
            } else {
                next()
            }
        }
    } catch
        (e) {
        console.log(e)
        next()
    }
})

export default router;