import Vue from 'vue'
import VueAxios from 'vue-axios'
import axios from 'axios'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import vueNumeralFilterInstaller from 'vue-numeral-filter';
import VueLodash from 'vue-lodash'
import lodash from 'lodash'
import * as VueGoogleMaps from 'vue2-google-maps'
import GmapCluster from 'vue2-google-maps/dist/components/cluster'

Vue.use(VueLodash, {lodash: lodash})
Vue.use(vueNumeralFilterInstaller);
Vue.use(VueAxios, axios)

Vue.component('GmapCluster', GmapCluster)
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyDwj7N4AIhxWiZTprDHmwo9Jiyk9gtrtJ0',
        libraries: 'places',
        installComponents: true,
    }
});