const baseUrl = process.env.VUE_APP_MARAUDERS_BASE_URL;
const authService = {
    loggedIn() {
        return !!(localStorage.getItem('_token'))
    },
    setToken(token) {
        localStorage.setItem('_token', token)
    },
    setUserProfile(userProfile) {
        localStorage.setItem('_userProfile', userProfile)
    },
    login() {
        window.location.href = `${baseUrl}web/login/salesforce`;
    },
    logout() {
        localStorage.removeItem('_token')
    },
}

export default authService
